import React, { useState, useEffect } from 'react';
import { GoogleAuthProvider, signInWithPopup, signOut, onAuthStateChanged } from "firebase/auth";
import { auth } from './firebaseConfig'; // Adjust the path as necessary
import './Signin.css';
import { Navigate, useNavigate } from 'react-router-dom';

import { db } from './firebaseConfig';
import { doc, setDoc, getDoc } from 'firebase/firestore';

function SignIn() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // This hook keeps track of the authentication state
    const unsubscribe = onAuthStateChanged(auth, currentUser => {
      setUser(currentUser);
    });

    return () => unsubscribe(); // Clean up the subscription
  }, []);

  const handleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        console.log('Success', result);
        const user = result.user;
  
        // Check if this is the first time the user has logged in
        const userRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(userRef);
  
        if (!docSnap.exists()) {
          // User does not exist, create new user document
          await setDoc(userRef, {
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
            postsLiked: [],
            createdAt: new Date()
          });
          console.log('New user entry created');
        }
      }).catch((error) => {
        console.error('Error during sign in', error);
      });
  };
  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        console.log('Sign-out successful.');
        navigate(0);
      }).catch((error) => {
        // An error happened.
        console.error('Error during sign out', error);
      });
  };

  if (user) {
    return (
      <div>
        <span>Welcome, {user.displayName || 'User'}! &nbsp;  </span> 
        <button className="button" onClick={handleSignOut}>LOGOUT</button>
      </div>
    );
  }

  return (
    <button className="button" onClick={handleSignIn}>SIGN IN</button>
  );
}

export default SignIn;
