import React, { useState, useEffect } from 'react';
// import Login from './components/Login';
import Main from './Main';
import './App.css';
// import { auth } from "./firebase";

function App() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    // const unsubscribe = auth.onAuthStateChanged(user => {
    //   setCurrentUser(user);
    // });

    // return () => unsubscribe();
  }, []);

  return (
    <div className="App"> 
      {/* {currentUser != null ? 
      <Main /> :
      <Login /> } */}
      <Main />
    </div>
  );
}

export default App;