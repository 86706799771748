import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SearchBar from './SearchBar';
import Homepage from './Homepage';
import Post from './Post';
import './Main.css';
// import SomeOtherComponent from './SomeOtherComponent'; // Placeholder for other components

function Main() {
  return (
    <Router>
      <div className="mainpage">
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/p" element={<Homepage />} />
          <Route path="/p/:searchTerm" element={<Post />} />
          {/* <Route path="/" element={<Homepage/>} /> */}

          {/* Component to display search results or handle the search term */}
          {/* <SomeOtherComponent /> */}
          {/* Other routes */}
        </Routes>

        {/* <iframe src="https://www.instagram.com/p/Cx_6K2mtAh0/embed" width="600" height="1000"></iframe> */}

      </div>
    </Router>
  );
}

export default Main;