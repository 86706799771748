import React, { useState, useEffect } from 'react';
import SearchBar from './SearchBar';
import Search from './Post';
import './Homepage.css';
import SignIn from './Signin';
import { collection, query, getDocs, limit, orderBy } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { db } from './firebaseConfig'; // Adjust this path if necessary
import Footer from './Footer';
// import SomeOtherComponent from './SomeOtherComponent'; // Placeholder for other components

function Homepage() {
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();

  const handleRedirect = (postId) => {
    // Using the navigate function from useNavigate() if you are in a React Router setup
    navigate(`/p/${postId}`);
  }

  useEffect(() => {
    const fetchPosts = async () => {
      const q = query(
        collection(db, "posts"),
        orderBy("views", "desc"), // Order posts by view count in descending order
        limit(20) // Limit to the top 10 posts
      );

      const querySnapshot = await getDocs(q);
      const postsData = [];
      querySnapshot.forEach((doc) => {
        postsData.push({ id: doc.id, ...doc.data() });
      });
      setPosts(postsData);
    };

    fetchPosts();
  }, []);

  return (
    <div className="homepage">
      <div className="home-header">

        <div className="home-title">INSTAWIKI</div>


        <div className="sign-in">        <SignIn></SignIn>
        </div>

        <div className="search-bar">        <SearchBar />
        </div>

      </div>

      <br></br>

      <div className="trend-header">Trending posts</div>
      <br /><br />

      <div className="trending-posts">

        {posts.map(post => (
          <div>

            <div key={post.id} className="iframe-container-hp" onClick={() => handleRedirect(post.id)}>
              <iframe
                src={`https://www.instagram.com/p/${post.id}/embed`}
                width="100%"
                height="100%"
                frameBorder="0"
                allowTransparency="true"
                allow="encrypted-media"
                scrolling="no"
                style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }}>
              </iframe>
              <div className="click-overlay" style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', cursor: 'pointer' }}></div>
            </div>

            <div>{post.views} views</div>
            <br></br>
          </div>

        ))}

        {/* {posts.map(post => (
          <a key={post.id} href={`/p/${post.id}`} style={{ display: 'block', width: '200px', height: '200px', position: 'relative', margin: '20px' }}>
            <iframe
              src={`https://www.instagram.com/p/${post.id}/embed`}
              width="200"
              height="200"
              frameBorder="0"
              style={{ position: 'absolute', left: '0', top: '0' }}
              title="Instagram Post"
              allowTransparency="true"
              allow="encrypted-media">
            </iframe>
            <div style={{ position: 'absolute', left: '0', top: '0', width: '100%', height: '100%', background: 'rgba(0,0,0,0.5)', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', visibility: 'hidden' }}>
              Click to view
            </div>
          </a>
        ))} */}

      </div>

      <br /><br />

      <Footer />

    </div>
  );
}

export default Homepage;