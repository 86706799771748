import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { db } from './firebaseConfig';
import { doc, getDoc, updateDoc, setDoc, collection, arrayUnion, increment } from 'firebase/firestore';
import { onAuthStateChanged } from "firebase/auth"; // Import this from Firebase auth
import { auth } from './firebaseConfig'; // Make sure auth is correctly imported
import "./Post.css";
import SignIn from './Signin';
import SearchBar from './SearchBar';
import Footer from './Footer';

const Post = () => {
  const { searchTerm } = useParams();
  const navigate = useNavigate(); // Use this for navigation on button click
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [editPopupVisible, setEditPopupVisible] = useState(false);
  const [editContent, setEditContent] = useState('');
  const [isUserSignedIn, setIsUserSignedIn] = useState(false); // State to track user sign-in
  const [addingTag, setAddingTag] = useState(false);
  const [tags, setTags] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [tagsEnlarged, setTagsEnlarged] = useState(false);

  const [editingTagIndex, setEditingTagIndex] = useState(-1); // To keep track of which tag is being edited
  const [tagDescription, setTagDescription] = useState(''); // To hold the current editing tag's description

  // Function to handle the start of editing a tag
  const handleEditTagStart = (index) => {
    setEditingTagIndex(index);
    setTagDescription(post.tags[index].description);
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
    setAddingTag(false);
  };

  const toggleTagSize = () => {
    setTagsEnlarged(!tagsEnlarged);
  };

  // Function to handle the tag description change
  const handleTagDescriptionChange = (event) => {
    setTagDescription(event.target.value);
  };

  const handleSaveTagEdit = async () => {
    if (editingTagIndex < 0 || editingTagIndex >= post.tags.length) {
      console.error('Invalid tag index');
      return;
    }

    const updatedTags = post.tags.map((tag, index) => {
      if (index === editingTagIndex) {
        return { ...tag, description: tagDescription };
      }
      return tag;
    });

    try {
      const logEntry = {
        postId: searchTerm,
        userId: auth.currentUser.uid, // Assuming you have access to the auth object
        timestamp: new Date().toISOString(), // Use ISO string for timestamp
        oldData: post.tags,
        newData: updatedTags,
        type: "edited tag"
      };

      // Reference to the logs collection
      const logsRef = doc(collection(db, 'editLogs'));
      await setDoc(logsRef, logEntry);
      console.log('Log entry added successfully');


      const postRef = doc(db, 'posts', searchTerm);
      await updateDoc(postRef, { tags: updatedTags });
      setPost({ ...post, tags: updatedTags });
      setTags(updatedTags); // Update the tags array in the state
      setEditingTagIndex(-1); // Reset editing index
      console.log('Tag updated successfully!');

      
      
    } catch (error) {
      console.error('Error updating tag:', error);
    }
  };

  const handleDeleteTag = async (index) => {
    if (index < 0 || index >= post.tags.length) {
      console.error('Invalid tag index');
      return;
    }

    const filteredTags = post.tags.filter((_, i) => i !== index);

    try {

      const logEntry = {
        postId: searchTerm,
        userId: auth.currentUser.uid, // Assuming you have access to the auth object
        timestamp: new Date().toISOString(), // Use ISO string for timestamp
        oldData: post.tags[index],
        type: "deleted tag",
      };

      // Reference to the logs collection
      const logsRef = doc(collection(db, 'editLogs'));
      await setDoc(logsRef, logEntry);
      console.log('Log entry added successfully');

      const postRef = doc(db, 'posts', searchTerm);
      await updateDoc(postRef, { tags: filteredTags });
      setPost({ ...post, tags: filteredTags });
      setTags(filteredTags); // Update the tags array in the state
      console.log('Tag deleted successfully!');

      window.location.reload()
    } catch (error) {
      console.error('Error deleting tag:', error);
    }
  };

  const toggleAddTag = () => {
    setAddingTag(!addingTag);
  };

  const saveTagToDatabase = async (newTag) => {
    // Assuming you have a unique postId and a collection for posts
    const postRef = doc(db, 'posts', searchTerm);
    try {
      await updateDoc(postRef, {
        tags: arrayUnion(newTag)  // Firebase function to add an item to an array in the document
      });
      console.log('Tag added successfully!');

      const logEntry = {
        postId: searchTerm,
        userId: auth.currentUser.uid, // Assuming you have access to the auth object
        timestamp: new Date().toISOString(), // Use ISO string for timestamp
        newData: newTag,
        type: "added tag"
      };

      // Reference to the logs collection
      const logsRef = doc(collection(db, 'editLogs'));
      await setDoc(logsRef, logEntry);
      console.log('Log entry added successfully');
      
      window.location.reload()
    } catch (error) {
      console.error('Error adding tag:', error);
    }
  };


  const handleIframeClick = (event) => {
    if (!addingTag) return;

    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left; // x position within the element.
    const y = event.clientY - rect.top;  // y position within the element.

    const newTag = { x, y, description: "Tag description" };
    setTags([...tags, newTag]);
    setAddingTag(false);

    saveTagToDatabase(newTag);
    // Call function to save to database here.
  };

  const instagramEmbedUrl = `https://www.instagram.com/p/${encodeURIComponent(searchTerm)}/embed`;

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsUserSignedIn(!!user); // !!user will be true if user is not null
    });

    return () => unsubscribe(); // Cleanup subscription
  }, []);

  useEffect(() => {
    const fetchAndIncrementViews = async () => {
      const postRef = doc(db, 'posts', searchTerm);

      // Get the current post data
      const docSnap = await getDoc(postRef);
      if (docSnap.exists()) {
        setPost(docSnap.data());

        // Increment the view count
        await updateDoc(postRef, {
          views: increment(1)
        });
      } else {
        console.log("No such post found");
        // Handle case where post does not exist
      }
    };

    fetchAndIncrementViews();
  }, [searchTerm]);  // searchTerm is the dependency for this effect


  useEffect(() => {
    if (!searchTerm) {
      setError('No search term provided');
      setLoading(false);
      return;
    }

    const fetchPost = async () => {
      setLoading(true); // Ensure loading state is set during new fetch
      try {
        console.log(searchTerm);
        const docRef = doc(db, 'posts', searchTerm);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setPost(docSnap.data());
        } else {
          console.log('No such post found');
          setPost(null); // Reset post state when no document is found
          setError('No such post found');
        }
      } catch (error) {
        console.error("Error fetching post:", error);
        setError('Error fetching post');
        setPost(null); // Ensure post is reset in case of error
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [searchTerm]);

  const handleAddPost = async () => {
    setLoading(true);
    try {
      // Assuming `searchTerm` is the Instagram post ID you want to use as the document ID
      const newPostRef = doc(db, 'posts', searchTerm);

      // Set the document with the UID as the Instagram post ID and "wiki" field
      await setDoc(newPostRef, {
        wiki: 'This is an instagram post.',
        tags: [],
        views: 0,
        userAdded: auth.currentUser.uid,
      });

      console.log('Document successfully written!');
      navigate(0);

      // Optionally navigate to the newly added post's page or show a success message
    } catch (error) {
      console.error('Error adding document: ', error);
      setError('Error adding new post');
    } finally {
      setLoading(false);
      navigate(0); // Use `navigate` to redirect to /{searchTerm}
    }
  };

  const handleEditPost = () => {
    setEditContent(post.wiki || '');
    setEditPopupVisible(true);
  };

  const handleSaveEdit = async () => {
    if (!isUserSignedIn) {
      alert('You must be signed in to edit the wiki!');
      return; // Stop the function if the user is not signed in.
    }

    const postRef = doc(db, 'posts', searchTerm);
    try {
      const oldData = post ? post.wiki : ''; // Store the current state before updating
      await updateDoc(postRef, { wiki: editContent });
      setPost({ ...post, wiki: editContent }); // Update local state
      setEditPopupVisible(false); // Close popup after save
      console.log('Wiki updated successfully!');

      // Proceed to log this change in the database
      const logEntry = {
        postId: searchTerm,
        userId: auth.currentUser.uid, // Assuming you have access to the auth object
        timestamp: new Date().toISOString(), // Use ISO string for timestamp
        oldData: oldData,
        newData: editContent,
        type: "edit wiki",
      };

      // Reference to the logs collection
      const logsRef = doc(collection(db, 'editLogs'));
      await setDoc(logsRef, logEntry);
      console.log('Log entry added successfully');
    } catch (error) {
      console.error('Error updating wiki or logging:', error);
      // Optionally, update error handling in your state or UI.
    }
  };


  if (loading) return <div><br></br>Loading...</div>;
  // if (error) return <div>Error: {error}</div>;

  return (
    <div className="post-page">

      <div className="header">
        <br></br>
        <br></br>
        <Link to="/" className="title">INSTAWIKI</Link>
        <br></br>

        <div className="login-button">
          <SignIn />
        </div>
        <br></br>

      </div>

      <div className="body">

        <div onClick={handleIframeClick} className={`iframe-container ${addingTag ? 'add-tag-mode' : ''}`}>

          <iframe
            src={instagramEmbedUrl}
            width="600"
            height="1000"
            frameBorder="0"
            allowTransparency="true"
            allow="encrypted-media">
          </iframe>

          {addingTag && (
            <div className="overlay">
              <p>Click a location to add tag</p>
            </div>
          )}

          {post && post.tags && post.tags.map((tag, index) => (
            <div key={index} className="tag-circle" style={{
              left: `${tag.x}px`,
              top: `${tag.y}px`,
              width: tagsEnlarged ? '100px' : '30px',  // Adjust size based on state
              height: tagsEnlarged ? '100px' : '30px',
              lineHeight: tagsEnlarged ? '70px' : '30px',  // Keep the number centered
              backgroundColor: tagsEnlarged ? 'orange' : 'rgba(128, 128, 128, 0.5)',  // Change color based on state
              fontSize: tagsEnlarged ? '30px' : '12px'  // Adjust font size based on state
            }}>
              {index + 1}
            </div>
          ))}

        </div>

        <div className="wiki">
          {post ? (
            <div>

              <div className="wiki-content">{post.wiki}</div>

              <div className="tags-list">
                {post && post.tags && post.tags.map((tag, index) => (
                  <div key={index} className="tag-item">
                    <div className="tag-circle-and-description">
                      <div className="tag-circle-right" style={{
                        backgroundColor: tagsEnlarged ? 'orange' : 'rgba(128, 128, 128, 0.5)',
                        width: '30px',
                        height: '30px',
                        color: 'black',
                      }}>
                        {index + 1}
                      </div>
                      <div className="tag-description">
                        {editingTagIndex === index ? (
                          <textarea
                            type="text"
                            value={tagDescription}
                            maxLength={500}
                            onChange={handleTagDescriptionChange}
                            style={{
                              fontSize: '16px',
                              width: '100%',
                              height: '100px',
                              padding: '5px'
                            }}  // Ensure the input field also looks good
                          />
                        ) : (
                          <span>{tag.description}</span>
                        )}
                      </div>
                    </div>
                    {isUserSignedIn && editMode && (
                      editingTagIndex === index ?
                        <>
                          <button onClick={handleSaveTagEdit}>SAVE</button>
                          <button onClick={() => setEditingTagIndex(-1)}>CANCEL</button>

                        </> :
                        <>
                          <button onClick={() => handleEditTagStart(index)}>EDIT</button>
                          <button onClick={() => handleDeleteTag(index)}>DELETE TAG</button>
                        </>


                    )}
                  </div>
                ))}
              </div>

              {isUserSignedIn && editMode && <button onClick={handleEditPost}>EDIT WIKI</button>}
              {isUserSignedIn && editMode && !addingTag && post.tags.length < 10 && <button onClick={toggleAddTag}>ADD TAG</button>}
              {isUserSignedIn && editMode && addingTag && <button onClick={toggleAddTag}>CANCEL ADD TAG</button>}


              {isUserSignedIn && editMode && !editPopupVisible && <button onClick={toggleEditMode}>DONE EDITING</button>}

              <br></br>
              {isUserSignedIn && !editMode && <button onClick={toggleEditMode}>EDIT</button>}

              {!editMode && post.tags && post.tags.length != 0 && (
                <button onClick={toggleTagSize}>
                  {tagsEnlarged ? 'SHRINK TAGS' : 'ENLARGE TAGS'}
                </button>
              )}
              <br></br>
              <br></br>

              <p>{post.views && post.views || 0} views</p>



              {!isUserSignedIn && <div>Log in to edit!</div>}


              <br></br>

            </div>
          ) : (
            <>
              <p>This post is not yet on instawiki.</p>
              {isUserSignedIn && <button onClick={handleAddPost}>ADD IT NOW!</button>}

              {!isUserSignedIn && <div>Sign in to add it now!</div>}

              <br></br>
              <br></br>

            </>
          )}

          {editPopupVisible && (
            <div className="edit-popup">
              <textarea
                value={editContent}
                maxLength={1500}
                onChange={(e) => setEditContent(e.target.value)}
              />
              <button onClick={handleSaveEdit}>SUBMIT</button>
              <button onClick={() => setEditPopupVisible(false)}>CANCEL</button>
            </div>
          )}



        </div>
      </div>


      <br></br>
      <br></br>
      <br></br>

      <Footer/>

    </div>
  );
};

export default Post;
