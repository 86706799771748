import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./SearchBar.css";

function getInstagramPostId(url) {
  const regex = /instagram\.com\/p\/([^/?]+)/;
  const match = url.match(regex);
  return match ? match[1] : "";
}

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate(); // Updated variable name to `navigate`

  const handleSearch = (event) => {
    event.preventDefault(); // Prevent the default form submission
    navigate(`/p/${encodeURIComponent(getInstagramPostId(searchTerm))}`); // Use `navigate` to redirect to /{searchTerm}
  };

  return (
    <form onSubmit={handleSearch} className="search-form">
      <input
        type="text"
        placeholder="Enter an Instagram post URL here"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        autoComplete="off"
        className="search-input"
      />
      <button type="submit" className="button">SEARCH</button>
    </form>
    

  );
};

export default SearchBar;
