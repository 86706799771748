// Footer.js
import React from 'react';
import './Footer.css'; // Make sure to create this CSS file

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-links">
        <a href="/terms.html" className="footer-link">Terms of Service</a>
        <a href="/privacy.html" className="footer-link">Privacy Policy</a>
      </div>
      <p className="footer-text">© 2024 Gueezy. All Rights Reserved.</p>
    </footer>
  );
}

export default Footer;